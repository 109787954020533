body {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

header {
  background-color: #333;
  color: white;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 60px;
  box-sizing: border-box;
}

header h1 {
  margin: 0;
  padding: 0px;
  line-height: 30px;
}

footer {
  background-color: #333;
  color: white;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35px;
  box-sizing: border-box;
}

main {
  display: flex;
  flex: 1;
  margin-top: 60px;
  margin-bottom: 35px;
  height: calc(100vh - 120px);
}



button{
  background-color: #4caf50;
  color: white;
  border: none;
}

button:hover {
  background-color: #489b4c; /* Cambiar el color de fondo al pasar sobre el botón */
}

button:active {
  transform: scale(0.95); /* Reducir la escala del botón al ser oprimido */
}


.menu {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  padding: 1rem;
  overflow-y: auto;
  height: 100%;
}

.menu-main {
  margin-top: 15px;
  padding: 0;
}

.menu-footer {
  margin: 0;
  padding: 0;
}

.menu-button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.menu-footer-button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  background-color: #5c0262;
  color: white;
  border: none;
  cursor: pointer;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.menu-tabs {
  background-color: #f0f0f0;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
}

.tab-button {
  padding: 0.5rem;
  width: 120px;
  background-color: #1ca057;
  border: none;
}

.tab-button.active {
  background-color: #f0762f;
}

.tab-button:hover {
  background-color: #f0762f;
}

.close-tab {
  color: #000000;
  border: none;
  cursor: pointer;
  margin-left: 6px;
}

.tab {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
}

.tab-content {
  display: none;
  padding: 1rem;
  background-color: #fff;
  max-width: 800px; /* Ancho máximo del contenido */
  width: 100%; /* Ocupa todo el ancho disponible hasta el max-width */
}

.tab-content.active {
  display: block;
}

