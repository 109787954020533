.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 200px;
    background-color: #f2f2f2;
    font-family: Arial, sans-serif;
}

.login-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
}

.logo-container {
    margin-bottom: 20px;
}

.logo {
    width: 200px;
}

h2 {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #333;
}

.input-group {
    margin-bottom: 15px;
    text-align: left;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

input[type="text"],
input[type="password"],
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #5c0262;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: #800887;
}